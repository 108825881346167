










































































































































import { computed, defineComponent } from '@vue/composition-api';
import { useGetters } from 'vuex-composition-helpers';

import { GET_OPERATIONS } from '@/store/operation.actions';

import Hero from '@/components/Hero.vue';
import OperationList from '@/components/OperationList.vue';
import ReportList from '@/components/ReportList.vue';

import { Operation } from '@/models/operation.model';
import { parseISO } from 'date-fns';
import { allCars } from '@/data/car.data';
import { GET_REPORTS } from '@/store/report.actions';
import { Report } from '@/models/report.model';
import { isImg } from '@/helpers/image-entry-helpers';

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

export default defineComponent({
  name: 'Home',
  // props: {
  // },
  components: {
    Hero,
    OperationList,
    ReportList,
    VuePdfEmbed
  },
  setup: (props, ctx) => {
    const getters = useGetters([GET_OPERATIONS, GET_REPORTS]);

    const operations = getters[GET_OPERATIONS];
    const lastOperations = computed(() => {
      return (operations.value as Operation[]).slice(0, 5);
    });

    const reports = getters[GET_REPORTS];
    const lastReports = computed(() => {
      return (reports.value as Report[]).slice(0, 6);
    });

    const now = new Date();
    const statistics = [
      {
        title: 'Fahrzeuge',
        value: computed(() => allCars.length),
        route: 'FZ_KO'
      },
      {
        title: `Einsätze im Jahr ${now.getFullYear()}`,
        value: computed(() => {
          return (operations.value as Operation[]).filter(op => {
            const dateStr = op.zeitstempel;
            if (!dateStr || dateStr.length === 0) {
              return false;
            }

            const date = parseISO(dateStr);
            const dateYear = date.getFullYear();
            return dateYear === now.getFullYear();
          }).length;
        }),
        route: 'OL'
      },
      {
        title: 'Aktive Mitglieder',
        value: 122,
        route: 'LZ_EV'
      }
    ];

    const eventHeaders = [
      {
        text: 'Bild',
        value: 'file'
      },
      {
        text: 'Datum',
        value: 'date'
      },
      {
        text: 'Beschreibung',
        value: 'description'
      }
    ];
    const events = [
      {
        date: '17.08.2024',
        description: 'Kameradschaftsfest Alverskirchen 2024',
        file: 'kameradschaftsfest2024.pdf',
        hidePreview: true
      }
    ];

    const openFile = (file: string, description: string) => {
      ctx.root.$router.push({
        name: 'VE',
        params: { file, name: description }
      });
    };

    const scrollToRef = (refName: string) => {
      const element = (ctx.refs[refName] as Vue).$el;
      element.scrollIntoView({ behavior: 'smooth' });
    };

    return {
      lastOperations,
      lastReports,
      statistics,
      eventHeaders,
      events,
      openFile,
      scrollToRef,
      isImg
    };
  }
});

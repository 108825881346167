
















import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'SectionHero',
  setup: (props, ctx) => {
    const carouselMinHeight = computed(() => {
      const height = ctx.root.$vuetify.breakpoint.mdAndUp
        ? '75vh'
        : ctx.root.$vuetify.breakpoint.xs
        ? '83vh'
        : '78vh';
      return `calc(${height} - ${ctx.root.$vuetify.application.top}px)`;
    });

    const carouselItemMinHeight = computed(() => {
      const height = ctx.root.$vuetify.breakpoint.mdAndUp
        ? '75vh'
        : ctx.root.$vuetify.breakpoint.xs
        ? '83vh'
        : '78vh';
      return `calc(${height} - ${ctx.root.$vuetify.application.top}px)`;
    });

    const carouselItems = computed(() => {
      const images = [
        require('@/assets/start1.jpg'),
        require('@/assets/start2.jpg')
      ];

      if (ctx.root.$vuetify.breakpoint.xs) {
        return [require('@/assets/start0_xs.jpg'), ...images];
      }

      if (ctx.root.$vuetify.breakpoint.sm) {
        return [
          require('@/assets/start0.jpg'),
          require('@/assets/start0_xs.jpg'),
          ...images
        ];
      }

      return [require('@/assets/start0.jpg'), ...images];
    });

    return {
      carouselMinHeight,
      carouselItemMinHeight,
      carouselItems
    };
  }
});



















































































































































































import {
  computed,
  defineComponent,
  inject,
  Ref,
  ref,
  watch
} from '@vue/composition-api';
import { useActions, useGetters } from 'vuex-composition-helpers';
import VueRouter from 'vue-router';

import {
  FETCH_ASSET,
  FETCH_IMAGE,
  REMOVE_IMAGE
} from '@/store/operation.actions';
import { GET_LOGGED_IN } from '@/store/auth.actions';

import { DELETE_REPORT } from '@/store/report.actions';
import { Report } from '@/models/report.model';
import {
  deleteImageEntry,
  watchImageEntries,
  calcPageCount,
  formatDate,
  formatDay,
  formatTime
} from '@/helpers/image-entry-helpers';

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

export default defineComponent({
  name: 'ReportList',
  props: {
    reports: {
      type: Array,
      default: () => []
    }
  },
  components: {
    VuePdfEmbed
  },
  setup: props => {
    const getters = useGetters([GET_LOGGED_IN]);
    const loggedIn = getters[GET_LOGGED_IN];

    const router = inject<VueRouter>('$router');
    const readReport = (report: Report) => {
      router?.push({
        name: 'RD',
        params: {
          id: report._id as string
        }
      });
    };

    const actions = useActions([
      DELETE_REPORT,
      REMOVE_IMAGE,
      FETCH_IMAGE,
      FETCH_ASSET
    ]);
    const deleteAction = actions[DELETE_REPORT];
    const removeImageAction = actions[REMOVE_IMAGE];

    const confirmDialog = ref(false);

    const deleteReport = async (report: Report) => {
      deleteImageEntry(report, removeImageAction, deleteAction, confirmDialog);
    };

    const editReport = async (report: Report) => {
      router?.push({
        name: 'RI',
        params: {
          id: report._id as string
        }
      });
    };

    const page = ref(1);
    const perPage = 8;

    const visibleReports = computed(() => {
      return (props.reports as Report[]).slice(
        (page.value - 1) * perPage,
        page.value * perPage
      );
    });

    const fetchImageAction = actions[FETCH_IMAGE];
    const fetchAssetAction = actions[FETCH_ASSET];
    const reportThumbnailUrls: Ref<{
      [key: string]: { type: 'img' | 'pdf' | 'placeholder'; url?: string };
    }> = ref({});

    watch(
      visibleReports,
      (reports, _oldReports) => {
        watchImageEntries(
          reports,
          reportThumbnailUrls,
          fetchAssetAction,
          fetchImageAction
        );
      },
      { immediate: true }
    );

    const pageCount = computed(() =>
      calcPageCount(props.reports as Report[], perPage)
    );

    const selectedForRemoveReportIdx = ref(-1);
    const selectedForRemoveReport = computed(() => {
      const idx = selectedForRemoveReportIdx.value;
      if (idx >= 0) {
        return visibleReports.value[idx];
      }
    });

    return {
      loggedIn,
      formatDate,
      formatDay,
      formatTime,
      readReport,
      deleteReport,
      editReport,
      confirmDialog,

      page,
      perPage,
      visibleReports,
      pageCount,
      reportThumbnailUrls,

      selectedForRemoveReportIdx,
      selectedForRemoveReport
    };
  }
});

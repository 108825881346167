





















































































































































































































































































































import {
  computed,
  defineComponent,
  inject,
  Ref,
  ref,
  watch
} from '@vue/composition-api';
import { useActions, useGetters } from 'vuex-composition-helpers';
import VueRouter from 'vue-router';

import { Operation } from '@/models/operation.model';
import {
  DELETE_OPERATION,
  FETCH_ASSET,
  FETCH_IMAGE,
  REMOVE_IMAGE
} from '@/store/operation.actions';
import { GET_LOGGED_IN } from '@/store/auth.actions';

import {
  calcPageCount,
  deleteImageEntry,
  watchImageEntries,
  formatDate,
  formatDay,
  formatTime
} from '@/helpers/image-entry-helpers';
import { extendWithAlarmTypeIcon } from '@/helpers/alarmtype-mapping';

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

export default defineComponent({
  name: 'OperationList',
  props: {
    operations: {
      type: Array,
      default: () => []
    }
  },
  components: {
    VuePdfEmbed
  },
  setup: props => {
    const getters = useGetters([GET_LOGGED_IN]);
    const loggedIn = getters[GET_LOGGED_IN];

    const router = inject<VueRouter>('$router');
    const readOp = (op: Operation) => {
      router?.push({
        name: 'OD',
        params: {
          id: op._id as string
        }
      });
    };

    const actions = useActions([
      DELETE_OPERATION,
      REMOVE_IMAGE,
      FETCH_IMAGE,
      FETCH_ASSET
    ]);
    const deleteAction = actions[DELETE_OPERATION];
    const removeImageAction = actions[REMOVE_IMAGE];

    const confirmDialog = ref(false);

    const deleteOp = async (op: Operation) => {
      deleteImageEntry(op, removeImageAction, deleteAction, confirmDialog);
    };

    const editOp = async (op: Operation) => {
      router?.push({
        name: 'OI',
        params: {
          id: op._id as string
        }
      });
    };

    const page = ref(1);
    const perPage = 5;

    const visibleOperations = computed(() => {
      return (props.operations as Operation[])
        .slice((page.value - 1) * perPage, page.value * perPage)
        .map(op => extendWithAlarmTypeIcon(op));
    });

    const fetchImageAction = actions[FETCH_IMAGE];
    const fetchAssetAction = actions[FETCH_ASSET];
    const operationThumbnailUrls: Ref<{
      [key: string]: { type: 'img' | 'pdf' | 'placeholder'; url?: string };
    }> = ref({});

    watch(
      visibleOperations,
      (operations, _oldOperations) => {
        watchImageEntries(
          operations as Operation[],
          operationThumbnailUrls,
          fetchAssetAction,
          fetchImageAction
        );
      },
      { immediate: true }
    );

    const pageCount = computed(() =>
      calcPageCount(props.operations as Operation[], perPage)
    );

    const selectedForRemoveOpIdx = ref(-1);
    const selectedForRemoveOp = computed(() => {
      const idx = selectedForRemoveOpIdx.value;
      if (idx >= 0) {
        return visibleOperations.value[idx];
      }
    });

    return {
      loggedIn,
      formatDate,
      formatDay,
      formatTime,
      readOp,
      deleteOp,
      editOp,
      confirmDialog,

      page,
      perPage,
      visibleOperations,
      pageCount,
      operationThumbnailUrls,

      selectedForRemoveOpIdx,
      selectedForRemoveOp
    };
  }
});
